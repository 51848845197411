import React from 'react'
import AddNewRecordHeader from './addNewRecordHeader'

function index() {
  return (
    <div>
      <AddNewRecordHeader />
    </div>
  )
}

export default index