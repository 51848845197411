import React from 'react'
import NavBar from '../../components/layouts/landingLayouts/Navbar'
import { Link } from "react-router-dom";

function login() {
    return (
        <div>
            <NavBar />
            <section className="authPage py-12 sm:py-16 lg:py-20 xl:py-24 bg-gray-50">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="max-w-xl mx-auto text-center">
                        <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
                            MyIdNow Organization Sign In
                        </h2>
                        <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">
                            MyIDnow allows you to view any citizen's identity and records like medical, criminal and financial using the citizen fingerprint.
                        </p>
                    </div>

                    <div className="max-w-lg mx-auto mt-8 bg-white shadow-xl rounded-2xl sm:mt-12">
                        <div className="p-6 sm:px-8">
                            <form className="space-y-5">
                                <div>
                                    <label for="orgid" className="sr-only">
                                        Organization Id
                                    </label>
                                    <input type="text" name="email" id="email" placeholder="Organization ID"
                                        className="block w-full px-6 py-4 text-base text-center text-gray-900 placeholder-gray-600 bg-white border border-gray-200 rounded-xl focus:bg-white focus:border-blue-600
                                    focus:ring-1 focus:ring-blue-600 focus:outline-none" />
                                </div>

                                <div>
                                    <label for="password" className="sr-only">
                                        Password
                                    </label>
                                    <input type="password" name="password" id="password" placeholder="Account Password"
                                        className="block w-full px-6 py-4 text-base text-center text-gray-900 placeholder-gray-600 bg-white border border-gray-200 rounded-xl focus:bg-white focus:border-blue-600 
                                    focus:ring-1 focus:ring-blue-600 focus:outline-none" />
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <input type="checkbox" name="rememberMe" id="rememberMe"
                                                className="w-4 h-4 text-blue-600 border border-gray-200 rounded focus:outline-none focus:ring-blue-600" />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label for="rememberMe" className="text-sm font-normal text-gray-700">
                                                Remember me
                                            </label>
                                        </div>
                                    </div>

                                    <a href="#" title="" className="text-sm font-normal text-gray-900 hover:underline">
                                        Forgot password?
                                    </a>
                                </div>

                                <Link to="/portal" className='inline-flex items-center justify-center w-full px-6 py-4 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700' >
                                    <button className="">
                                        Sign In
                                    </button>
                                </Link>

                            </form>

                            <div className="mt-6 space-y-6 text-center">
                                <p className="text-sm font-normal text-gray-500">
                                    Don't have an account? <a href="#" title="" className="text-sm font-semibold text-blue-600 hover:underline">Talk to Us</a>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default login