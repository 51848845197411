import React from 'react'
import PortalMainLayout from '../../components/layouts/portalLayouts/mainLayout'
import UniversalPageSwitchLayout from '../../components/layouts/portalLayouts/universalPageSwitchLayout'

function index() {
  return (
    <PortalMainLayout>
      <UniversalPageSwitchLayout page="index" />
    </PortalMainLayout>
  )
}

export default index