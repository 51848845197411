import React from 'react'
import SearchesSideCard from '../portalLayouts/searchesSideCard'
import { Link, useLocation } from 'react-router-dom';
import myIdNowLightLogo from '../../../assets/images/logos/myIdNow-LightLogo.png'

function PortalLayout(props) {
  const location = useLocation()
  const { window, children } = props;

  const setBgColor = (path) => {
    if (location.pathname === path) {
      return "bg-gray-700"
    }
  }
  return (
    <div className="flex flex-1 bg-gray-50">
      <div className="min-h-full h-screen sticky top-0 sideNav hidden bg-gray-900 md:flex md:w-64 md:flex-col">
        <div className="flex flex-col pt-8 overflow-y-auto">

          <div className="flex items-center flex-shrink-0 px-4">
            <Link to="/portal">
              <a href="" title="" className="">
                <img className='lightLogo inline-flex' src={myIdNowLightLogo} alt="" srcset="" />
              </a>
            </Link>
          </div>

          <div className="flex flex-col justify-between flex-1 h-full mt-3">
            <div className="space-y-4">
              <nav className="flex-1 space-y-2">

                <Link to="/portal">
                  <a title="" className={`flex items-center px-4 py-3 text-sm font-medium text-white transition-all duration-200 mt-2 hover:bg-gray-700 group ${setBgColor("/portal")}`}>
                    <svg className="flex-shrink-0 w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    Dashboard
                  </a>
                </Link>

                <Link to="/portal/findcitizen">
                  <a title="" className={`flex items-center px-4 py-3 text-sm font-medium text-white transition-all duration-200 mt-2 hover:bg-gray-700 group ${setBgColor("/portal/findcitizen")}`}>
                    <svg className="flex-shrink-0 w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>

                    Search Citizen
                  </a>
                </Link>

                <Link to="/portal/newrecord">
                  <a title="" className={`flex items-center px-4 py-3 text-sm font-medium text-white transition-all duration-200 mt-2 hover:bg-gray-700 group ${setBgColor("/portal/newrecord")}`}>
                    <svg className="flex-shrink-0 w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                    </svg>

                    Add Citizen Record
                  </a>
                </Link>

                <Link to="/portal/issuecertificate">
                  <a title="" className={`flex items-center px-4 py-3 text-sm font-medium text-white transition-all duration-200 mt-2 hover:bg-gray-700 group ${setBgColor("/portal/issuecertificate")}`}>
                    <svg className="flex-shrink-0 w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>

                    Issue Certificate
                  </a>
                </Link>

                <Link to="/portal/searchlogs">
                  <a title="" className={`flex items-center px-4 py-3 text-sm font-medium text-white transition-all duration-200 mt-2 hover:bg-gray-700 group ${setBgColor("/portal/searchlogs")}`}>
                    <svg className="flex-shrink-0 w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    Search Logs
                  </a>
                </Link>

                <Link to="/portal/teammembers">
                  <a title="" className={`flex items-center px-4 py-3 text-sm font-medium text-white transition-all duration-200 mt-2 hover:bg-gray-700 group ${setBgColor("/portal/teammembers")}`}>
                    <svg className="flex-shrink-0 w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    Team Members
                  </a>
                </Link>

                <Link to="/portal/accountsettings">
                  <a title="" className={`flex items-center px-4 py-3 text-sm font-medium text-white transition-all duration-200 mt-2 hover:bg-gray-700 group ${setBgColor("/portal/accountsettings")}`}>
                    <svg className="flex-shrink-0 w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    Account Settings
                  </a>
                </Link>

                <Link to="/portal/logout">
                  <a title="" className={`flex items-center px-4 py-3 text-sm font-medium text-white transition-all duration-200 mt-2 hover:bg-gray-700 group ${setBgColor("/portal/logout")}`}>
                    <svg className="flex-shrink-0 w-5 h-5 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg>
                    Logout
                  </a>
                </Link>

              </nav>

              {/* <hr className="border-gray-700" /> */}
            </div>

            <div className="sideBarAnalyticsCard mx-4 text-center mt-4">
              <SearchesSideCard />
            </div>

            <div className="px-5 pb-8 mt-8">
              <div className="flex items-center space-x-6">
                <a href="#" title="" className="text-xs font-medium text-gray-400 hover:text-white"> Privacy Policy </a>
                <a href="#" title="" className="text-xs font-medium text-gray-400 hover:text-white"> Terms of Service </a>
              </div>
              <p className="mt-4 text-xs font-medium text-gray-400">© 2023 myIdNow</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1">
        <main>
          <div className="py-6">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
              {children}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default PortalLayout