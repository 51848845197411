import React from 'react'
import DashboardFeatures from './dashboardFeatures'
import SearchCitizenArea from './searchCitizenArea'

function index() {
  return (
    <div>
      <div className='mb-6'> 
          Dashboard
      </div>

      <DashboardFeatures />
      <SearchCitizenArea />
      </div>
  )
}

export default index