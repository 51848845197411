import React from 'react'

function index() {
    return (
        <section className="mt-10 py-12 bg-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="xl:flex xl:items-center xl:justify-between">
                <h2 className="text-xl font-bold text-center text-black xl:text-left font-pj">Organizations We Target To Partner With</h2>

                <div className="grid items-center grid-cols-1 mt-10 gap-y-6 xl:mt-0 sm:grid-cols-2 sm:gap-y-8 lg:grid-cols-4 lg:gap-x-8">
                    <img className="partnersLogos object-contain w-auto mx-auto h-9" src="https://www.developmentsolutions.co.zw/sites/default/files/ministry%20of%20health.jpg" alt="" />
                    <img className="partnersLogos object-contain w-auto mx-auto h-9" src="https://pbs.twimg.com/media/CrRo-GWXgAAcsNb?format=jpg&name=4096x4096" alt="" />
                    <img className="partnersLogos object-contain w-auto mx-auto h-9" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Flag_of_the_Zimbabwe_Defence_Forces.svg/1200px-Flag_of_the_Zimbabwe_Defence_Forces.svg.png" alt="" />
                    <img className="partnersLogos object-contain w-auto mx-auto h-9" src="https://www.zimbabwesituation.com/wp-content/uploads/2019/01/rbz-logo.jpg" alt="" />
                </div>
            </div>
        </div>
    </section>
        
    )
}

export default index