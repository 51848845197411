import React from 'react'
import PortalMainLayout from '../../components/layouts/portalLayouts/mainLayout'
import UniversalPageSwitchLayout from '../../components/layouts/portalLayouts/universalPageSwitchLayout'

function issuecertificate() {
    return (
        <PortalMainLayout>
             <UniversalPageSwitchLayout page="issuecertificate" />
        </PortalMainLayout>
    )
}

export default issuecertificate