import React from 'react'

function fetchTeamMembers() {
  return (
    <div>
    <div className="mt-8 sm:flex sm:items-center sm:justify-between">
        <div>
            <p className="text-base font-bold text-gray-900">Manage Team Members</p>
            <p className="mt-2 text-sm font-medium text-gray-500">all team members and their statuses</p>
        </div>

        <div className="mt-4 sm:mt-0">
            <label for="" className="sr-only">Team Member Name</label>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                </div>

                <input type="search" name="" id="" className="border block w-full py-2 pl-10 
                border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm" placeholder="Team member name" />
            </div>
        </div>
    </div>

    <div className="mt-5 w-full pb-1 overflow-x-auto">
        <div className="border-b border-gray-200">
            <nav className="flex -mb-px space-x-10">

                <a href="#" className="py-4 text-sm font-medium text-indigo-600 transition-all duration-200 
                border-b-2 border-indigo-600 whitespace-nowrap">All Team Members</a>

                <a href="#" className="py-4 text-sm font-medium text-gray-500 transition-all duration-200 border-b-2 
                border-transparent hover:border-gray-300 whitespace-nowrap">Verified Team Members</a>

                <a href="#" className="py-4 text-sm font-medium text-gray-500 transition-all duration-200 border-b-2 
                border-transparent hover:border-gray-300 whitespace-nowrap">Unverified Team Members</a>

            </nav>
        </div>
    </div>

</div>
  )
}

export default fetchTeamMembers