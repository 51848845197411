import React from 'react'
import PortalMainLayout from '../../components/layouts/portalLayouts/mainLayout'
import UniversalPageSwitchLayout from '../../components/layouts/portalLayouts/universalPageSwitchLayout'

function teammembers() {
    return (
        <PortalMainLayout>
            <UniversalPageSwitchLayout page="teammembers" />
        </PortalMainLayout>
    )
}

export default teammembers