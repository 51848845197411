import React from 'react'
import PortalMainLayout from '../../components/layouts/portalLayouts/mainLayout'
import UniversalPageSwitchLayout from '../../components/layouts/portalLayouts/universalPageSwitchLayout'

function newrecord() {
    return (
        <PortalMainLayout>
            <UniversalPageSwitchLayout page="newrecord" />
        </PortalMainLayout>
    )
}

export default newrecord