import React from 'react'
import { Card, Text } from "@nextui-org/react";
import { Link, useLocation } from 'react-router-dom';

function SearchesSideCard() {
    return (
        <Card className='statsSiderbarCard'>
            <Card.Body className=''>
                <Text className='storageCardText'>
                    0 searches made
                </Text>

                <div className='mx-4 mt-4'>
                    <Link to="/portal/searchlogs">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
                    >
                        <svg className="w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                        <h5 className='upgradeNowText'>
                            View Logs
                        </h5>
                    </button>
                    </Link>
                </div>
            </Card.Body>
        </Card>
    )
}

export default SearchesSideCard