import React from 'react'
import InteractiveLandingVideo from '../../../../assets/videos/interactiveLandingVideo.mp4'

function index() {
  return (
    <section className="pb-4 bg-white">
    <div className="overflow-hidden sm:pt-12 lg:relative lg:py-20">
      <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
        <div className="lg:mt-16">
          <div className="mx-auto text-center sm:max-w-lg lg:max-w-xl lg:text-left lg:mx-0">
            <h1
              className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl xl:text-7xl sm:tracking-tight">
              Solving identity crisis using biometric
            </h1>
            <p className="mt-6 text-lg leading-7 text-gray-700 lg:leading-8 lg:text-xl">
              MyIdNow gives the relevant authorities the features & capacity to identify, recognize and retrieve citizens identities, records like medical, financial, health, next of kin and other 
              relevant records using their biometric fingerprints in their presence or in cases where they cannot speak for themselves. 
              These includes Police, Hospitals, Clinics, Forensics, Banks and Financial Institutions and also Private Companies.
            </p>
          </div>

        </div>
      </div>

      <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
        <div className="py-12 mt-6 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <video className='ml-20' autoplay="" loop="" poster="/web-images/banner-home-placeholder.webp" src={InteractiveLandingVideo}></video>
        </div>
      </div>
      
    </div>
  </section>
  )
}

export default index