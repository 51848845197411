import React from 'react'

function addNewRecordHeader() {
  return (
    <div>
      <div>
        <p className="headerTexts text-base font-bold text-gray-900">Add new record</p>

        <div className="border mt-5 border-indigo-600 rounded-lg bg-indigo-50">
          <div className="px-4 py-5 sm:p-6">
            <div className="md:flex md:items-center md:justify-between">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
              </svg>

              <div className="flex-1 max-w-xs mt-4 md:mt-0 md:ml-6">
                <p className="text-base font-bold text-gray-900">Citizen Records</p>
                <p className="mt-1 text-sm font-medium text-gray-500">Add new records to citizen or add new citizen.</p>
              </div>

              <div className="flex items-center justify-start mt-6 space-x-6 md:ml-auto md:justify-end md:mt-0 md:space-x-reverse">
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 
                        bg-indigo-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-800 md:order-last"
                >
                  REGISTER NEW CITIZEN
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default addNewRecordHeader