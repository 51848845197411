import React from 'react'
import { Card, Text, Button } from '@nextui-org/react'

function SearchCitizenArea() {
    return (
        <div>
            <div className='my-6'>
                Search for Citizen
            </div>

            <Card css={{ mw: "100%", borderRadius: "5px" }} variant="bordered">
                <Card.Body className='m-4 text-center'>
                    <h5 className='text-center mb-4'>Upload Citizen Finger prints </h5>
                    <h5 className='text-center mb-4'>*please upload visible finger prints to search for citizen.</h5>

                    <div className='text-center mb-8S' css={{ display: 'flex', alignItems: 'center' }}>
                        <input className='mb-4' type="file" id="myFile" name="filename"></input>
                    </div>

                    <div className="searchBtn mb-2 findPatientBtn d-grid text-center px-5 mt-5">
                        <Button className='text-center py-4 mx-auto' flat color="primary">
                            SEARCH
                        </Button>
                    </div>

                </Card.Body>
            </Card>

        </div>
    )
}

export default SearchCitizenArea