import React from 'react'
import PortalMainLayout from '../../components/layouts/portalLayouts/mainLayout'
import UniversalPageSwitchLayout from '../../components/layouts/portalLayouts/universalPageSwitchLayout'

function accountsettings() {
    return (
        <PortalMainLayout>
            <UniversalPageSwitchLayout page="accountsettings" />
        </PortalMainLayout>
    )
}

export default accountsettings