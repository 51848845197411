import React from 'react'
import PortalMainLayout from '../../components/layouts/portalLayouts/mainLayout'
import UniversalPageSwitchLayout from '../../components/layouts/portalLayouts/universalPageSwitchLayout'

function searchlogs() {
    return (
        <PortalMainLayout>
             <UniversalPageSwitchLayout page="searchlogs" />
        </PortalMainLayout>
    )
}

export default searchlogs