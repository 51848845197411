import React from 'react'
import NavBar from '../components/layouts/landingLayouts/Navbar'
import HeroSection from '../components/mixedComponents/landingComponents/HeroSection'
import Partners from '../components/mixedComponents/landingComponents/Partners'

function index() {
  return (
    <div>
      <NavBar />
      <HeroSection />
      <Partners />
    </div>
  )
}

export default index