import React from 'react'

// import the pages components
import IndexPage from '../../mixedComponents/portalComponents/index'
import FindCitizen from '../../mixedComponents/portalComponents/findcitizen'
import IssueCertificate from '../../mixedComponents/portalComponents/issuecertitificate'
import NewCitizenRecord from '../../mixedComponents/portalComponents/newrecord'
import SearchLogs from '../../mixedComponents/portalComponents/searchlogs'
import TeamMembers from '../../mixedComponents/portalComponents/teammembers'
import AccountSettings from '../../mixedComponents/portalComponents/accountsettings'
import Logout from '../../mixedComponents/portalComponents/logout'

function UniversalPageSwitchLayout(props) {
    const page = props.page

    if (page === "index") {
        return (
            <IndexPage />
        )

    } else if (page === "findcitizen") {
        return (
            <FindCitizen />
        )

    } else if (page === "newrecord") {
        return (
            <NewCitizenRecord />
        )
    } else if (page === "issuecertificate") {
        return (
            <IssueCertificate />
        )

    } else if (page === "searchlogs") {
        return (
            <SearchLogs />
        )

    } else if (page === "teammembers") {
        return (
            <TeamMembers />
        )

    } else if (page === "accountsettings") {
        return (
            <AccountSettings />
        )

    } else if (page === "logout") {
        return (
            <Logout />
        )

    } else {
        return (
            <h5>Page not found</h5>
        )
    }
}

export default UniversalPageSwitchLayout