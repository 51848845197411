import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// import Landing Pages
import Index from './pages/index'

// import Login
import Login from './pages/auth/login'

// import PortalPages
import PortalIndex from './pages/portal'
import Findcitizen from './pages/portal/findcitizen'
import NewRecord from './pages/portal/newrecord'
import IssueCertificate from './pages/portal/issuecertificate'
import SearchLogs from './pages/portal/searchlogs'
import TeamMembers from './pages/portal/teammembers'
import AccountSettings from './pages/portal/accountsettings'
import Logout from './pages/portal/logout'

// import not found page
import NotFoundPage from './pages/notFound'

function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />

          {/* auth pages */}
          <Route path="/auth/login" element={<Login />} />

          {/* portal pages */}
          <Route path="/portal" element={<PortalIndex />} />
          <Route path="/portal/findcitizen" element={<Findcitizen />} />
          <Route path="/portal/newrecord" element={<NewRecord />} />
          <Route path="/portal/issuecertificate" element={<IssueCertificate />} />
          <Route path="/portal/searchlogs" element={<SearchLogs />} />
          <Route path="/portal/teammembers" element={<TeamMembers />} />
          <Route path="/portal/accountsettings" element={<AccountSettings />} />
          <Route path="/portal/logout" element={<Logout />} />

          {/* not found page */}
          <Route path='*' element={< NotFoundPage />} />
        </Routes>
        
      </BrowserRouter>

    </div>
  );
}

export default App;