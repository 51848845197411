import React from 'react'
import TeamMembersHeader from './teamMembersHeader'
import FetchTeamMembers from './fetchTeamMembers'

function index() {
  return (
    <div>
      <TeamMembersHeader />
      <FetchTeamMembers />
    </div>
  )
}

export default index