import React from 'react'
import { Card } from "@nextui-org/react";

function dashboardsCards(props) {
    const name = props.name
    const iconPath = props.iconPath

    return (
        <Card className='dashboardCards' isPressable variant="bordered" css={{ mw: "400px", borderRadius: "8px", borderColor: "" }}>
            <Card.Body className='m-0 p-2' style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d={iconPath} />
                </svg>

                <p className='mt-0 text-center'>{name}</p>
            </Card.Body>
        </Card>
    )
}

export default dashboardsCards